<template>
    <div>
        <b-container>
            <b-row
                cols="5"
                align-content="between"
            >
                <b-col cols="12" md="9">
                    <h3>Edit Device System Config</h3>
                </b-col>
                <b-col cols="12" md="3">
                    <admin-device-menu></admin-device-menu>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p>{{devCustomer}} - {{devId}}</p>
                </b-col>
            </b-row>
        </b-container>
        <hr>
        <b-container>
            <b-row
                no-gutters
                cols="1"
                cols-sm="1"
                cols-md="2"
                cols-lg="3"
                align-v="start"
                align-h="center"
            >
                <div>
                    <b-col>
                        <b-card class="mb-1">
                            <sys-settings></sys-settings>
                        </b-card>
                    </b-col>
                </div>
                <div v-if="devType === 'InsectControl'">
                    <b-col>
                        <b-card class="mb-1">
                            <cfg-settings></cfg-settings>
                        </b-card>
                    </b-col>
                </div>
                <div v-else-if="devType === 'CoolMist'">
                    <b-col>
                        <b-card class="mb-1">
                            <cfg-settings-cool-mist></cfg-settings-cool-mist>
                        </b-card>
                    </b-col>
                </div>
            </b-row>
        </b-container>
        <hr>
        <div class="text-center">
            <b-button @click="saveCfgSettings" variant="outline-primary">
                <b-icon icon="cloud-arrow-up"></b-icon> Save
            </b-button>
        </div>
    </div>
</template>

<script>
import SysSettings from "@/components/AdminModules/Devices/SysSettings";
import CfgSettings from "@/components/AdminModules/Devices/CfgSettings";
import CfgSettingsCoolMist from "@/components/AdminModules/Devices/CfgSettingsCoolMist";
import { EventBus } from '@/plugins/event-bus';
import AdminDeviceMenu from "@/components/AdminDeviceMenu";

export default {
    name: "AdminEditSysConfig",
    props: ['id'],
    components: {
      SysSettings,
      CfgSettings,
      CfgSettingsCoolMist,
      AdminDeviceMenu
    },
    data() {
        return {
            devId: null,
            userId: null,
            devType: ""
        }
    },
    created() {
        this.devId = this.$route.params.id;
        this.userId = this.devIdToUserId(this.devId);
        this.devType = this.devIdToDevType(this.devId);
        this.$store.dispatch('getDeviceSysOther', {
            devId: this.devId,
            userId: this.userId
        }).then(() => {
            EventBus.$emit("UpdateDeviceSys");
        }).catch(() => {
            console.log("Error Getting Device Sys Settings");
        });
        this.$store.dispatch('getDeviceCfgOther', {
            devId: this.devId,
            userId: this.userId
        }).then(() => {
            if (this.devType === "InsectControl")
                EventBus.$emit("UpdateDeviceCfg");
            else if (this.devType === "CoolMist")
                EventBus.$emit("UpdateDeviceCfgCool");
        }).catch(() => {
            console.log("Error Getting Device Cfg Settings");
        });
    },
    mounted() {

    },
    methods: {
        devIdToUserId(value) {
            if (this.$store.getters.adminDevList.length !== 0) {
                const devList = this.$store.getters.adminDevList;
                for (let i = 0; i < devList.length; i++) {
                    if (devList[i].deviceId === value)
                        return devList[i].userId;
                }
            }
            return 'None';
        },
        devIdToDevType(value) {
            if (this.$store.getters.adminDevList.length !== 0) {
                const devList = this.$store.getters.adminDevList;
                for (let i = 0; i < devList.length; i++) {
                    if (devList[i].deviceId === value)
                        return devList[i].deviceType;
                }
            }
            return 'None';
        },
        saveCfgSettings() {
            console.log("Save Settings");
            this.$store.dispatch('setDeviceCfg', {
                id: this.devId,
                userId: this.userId,
                cfg: this.$store.getters.deviceCfg
            });
            this.$store.dispatch('setDeviceSys', {
                id: this.devId,
                userId: this.userId,
                cfg: this.$store.getters.deviceSys
            }).then(() => {
                this.$bvToast.toast('Setting Saved', {
                    title: 'Save Settings',
                    variant: 'success',
                    solid: true
                });
                setTimeout(() => {
                    this.$router.back();
                }, 1000);
            }).catch(() => {
                this.$bvToast.toast('Error Saving Settings', {
                    title: 'Save Settings',
                    variant: 'danger',
                    solid: true
                });
            });
        }
    },
    computed: {
        devCustomer () {
            if (this.$store.getters.deviceSys === null)
                return "";
            else
                return this.$store.getters.deviceSys.sysName;
        }
    }
}
</script>

<style scoped>

</style>
